import { ChangeEvent, ForwardedRef, forwardRef } from 'react'

import Checkbox, { CheckboxProps } from '../inputs/checkbox.input'
import FieldSet from './field-set'
import { FormFieldProps } from './types'
import { onBlurHandler, useRemoveFieldOnUnmount } from './utils'

export type CheckboxFieldProps = Omit<CheckboxProps, 'onChange'> &
  FormFieldProps<boolean> & {
    onChange?: (checked: boolean, value?: string) => void
  }

const CheckboxField = (
  {
    form,
    name,
    wrapperClassName,
    isArrayField,
    arrayFieldName,
    arrayFieldIndex,
    label,
    validators,
    defaultValue,
    onChange,
    onBlur,
    removeFieldOnUnmount,
    ...rest
  }: CheckboxFieldProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  useRemoveFieldOnUnmount(form, name, removeFieldOnUnmount)

  return (
    <FieldSet
      name={name}
      form={form}
      wrapperClassName={wrapperClassName}
      arrayFieldIndex={arrayFieldIndex}
      arrayFieldName={arrayFieldName}
      isArrayField={isArrayField}
      {...rest}
    >
      <form.Field name={name} validators={validators} defaultValue={defaultValue}>
        {(field) => (
          <Checkbox
            {...rest}
            ref={ref}
            name={name}
            checked={field.state.value}
            hasError={field.state.meta.errors?.length > 0}
            onBlur={onBlurHandler(field, onBlur)}
            onChange={(event: ChangeEvent<HTMLInputElement>, value?: string) => {
              onChange?.(event.target.checked, value)
              field.handleChange(event.target.checked)
            }}
            label={label}
          />
        )}
      </form.Field>
    </FieldSet>
  )
}

export default forwardRef<HTMLInputElement, CheckboxFieldProps>(CheckboxField)
