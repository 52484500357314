import { useForm } from '@tanstack/react-form'
import clsx from 'clsx'
import debug from 'debug'
import { ReactNode, useState } from 'react'

import Form from '../fields/form'
import { RenderNavItemDetailsProps, StepConfig, StepValues, SubmitStep } from './types'

const logger = debug('app:multistep')

interface MultiStepFlowProps<T extends StepValues = StepValues> {
  stepConfig: StepConfig<T>
  initialStepValues?: T
  initialStepIndex?: number
  title?: string
  description?: string
  breadCrumb?: string
  // Will automatically be hidden if there is only one step, but can be forced hidden with this
  hideStepNav?: boolean
  // Don't use Vendia purple bg, more generic white
  isWhiteLabel?: boolean
  renderNavItemDetails?: (props: RenderNavItemDetailsProps<T>) => ReactNode
}

function MultiStepFlow<T extends StepValues = StepValues>({
  stepConfig,
  initialStepValues = {} as T,
  hideStepNav = false,
  isWhiteLabel = false,
  initialStepIndex = 0,
  title,
  description,
  breadCrumb,
  renderNavItemDetails,
}: MultiStepFlowProps<T>) {
  // We also provide the form to the step components
  const form = useForm({ defaultValues: initialStepValues })

  // TODO: store stepId state in query param and find stepIndex from that
  //   if URL has step other then first step on full page load, disregard and use first step instead
  //   This way, we get broweer back/forward in wizards but full page load will reset to first step because
  //   we're not storing state in local storage or anything yet and typically needs to be built up from first step
  const [currStepIndex, setCurrStepIndex] = useState(initialStepIndex)
  const [stepValues, setStepValues] = useState(initialStepValues)
  const currStep = stepConfig[currStepIndex]
  const currStepId = currStep.id

  const showStepNav = stepConfig.length > 1 && !hideStepNav && currStep.showStepNav !== false

  function setCurrStepId(stepId: string) {
    const index = stepConfig.findIndex((step) => step.id === stepId)
    setCurrStepIndex(index)
  }

  // Returns new stepValues or null if form is invalid
  // User cannot move FORWARD until errors are sorted for current page
  const submitStep: SubmitStep<T> = async ({ nextStepIndex, nextStepId }) => {
    if (nextStepId && nextStepIndex) {
      throw new Error('Specify either nextStepId or nextStepIndex')
    }
    if (nextStepId === undefined && nextStepIndex === undefined) {
      throw new Error('Specify either nextStepId or nextStepIndex')
    }
    if (nextStepId) {
      nextStepIndex = stepConfig.findIndex((step) => step.id === nextStepId)
    }
    if (nextStepIndex === undefined) {
      throw new Error('nextStepIndex not found')
    }

    // Validate current step
    const errors = await form.validateAllFields('submit')
    if (errors.length > 0) {
      logger('Form errors detected', errors)

      // If trying to advance to next step or submitting form, but staying on same step...
      if (nextStepIndex >= currStepIndex || nextStepId === currStepId) {
        // Exit and deal with validation errors on this page
        logger('Cannot advance until form is valid')
        return null
      }
    }

    // Merge current step values into state
    // //  (But first remove formValues where value is undefined – a form element may have been unmounted from the
    // //  page prior to submission or validation so it will end up as undefined in the formValues object)
    // const formValues = Object.entries(form.state.values).reduce(
    //   (acc, [k, v]) => {
    //     if (v !== undefined) {
    //       acc[k] = v
    //     }
    //     return acc
    //   },
    //   {} as Record<string, any>,
    // )

    const updatedStepValues = { ...stepValues, ...form.state.values }
    logger(`latest stepValues:`, updatedStepValues, form.state.values)

    console.log(`latest stepValues:`, updatedStepValues, form.state.values)
    setStepValues(updatedStepValues)
    setCurrStepIndex(nextStepIndex)

    // Return new stepValues synchronously in case there is logic immediately
    // following this that needs them (as react setState is async)
    return updatedStepValues
  }

  const { StepComponent } = stepConfig[currStepIndex]

  // Some steps are like sub-steps (pre-steps, post-steps, whatever) - we want to step through them like normal,
  // but not muck up the top stepper nav with extra steps. So we filter them out here.
  const filteredStepConfigForNav = stepConfig.filter((s) => s.showInStepNav !== false)
  // When a step has showInStepNav===false, we want to remain on the same step index for consecutive steps
  const currentNavStepIndex = stepConfig.reduce((acc, curr, stepIndex) => {
    if (curr.showInStepNav === false && stepIndex <= currStepIndex) {
      return acc - 1
    }
    return acc
  }, currStepIndex)

  const wrapperClasses = clsx('flex w-full flex-1 flex-col')

  return (
    <div className={wrapperClasses}>
      <div className={'flex w-full flex-1 flex-col items-center justify-center'}>
        <div
          className={`mb-6 mt-2 flex w-full max-w-md items-center justify-evenly gap-1 font-bold text-white ${
            !showStepNav ? '!mb-0 hidden' : ''
          }`}
        >
          {filteredStepConfigForNav.map(({ navTitle, showInStepNav }, stepIndex) => {
            if (showInStepNav === false) {
              return null
            }
            return (
              <div
                key={stepIndex}
                className={clsx(
                  'h-4 w-full first:rounded-l-full last:rounded-r-full',
                  stepIndex > currentNavStepIndex && 'bg-neutral-3',
                  stepIndex <= currentNavStepIndex && 'bg-violet-800',
                )}
              />
            )
          })}
        </div>
        <Form className={'flex w-full flex-1 flex-col items-center justify-center'} form={form}>
          <StepComponent
            form={form}
            currStepId={currStepId}
            setCurrStepId={setCurrStepId}
            currStepIndex={currStepIndex}
            setCurrStepIndex={setCurrStepIndex}
            stepValues={stepValues}
            setStepValues={setStepValues}
            submitStep={submitStep}
          />
        </Form>
      </div>
      {/* <DevTool control={formMethods.control} /> */}
    </div>
  )
}

export default MultiStepFlow
