import ListboxInput, { ListboxInputProps } from '../inputs/listbox.input'
import FieldSet from './field-set'
import { FormFieldProps } from './types'
import { onBlurHandler, useRemoveFieldOnUnmount } from './utils'

export type ListboxFieldProps = Omit<ListboxInputProps, 'onChange'> & FormFieldProps

const ListboxField = ({
  form,
  name,
  wrapperClassName,
  validators,
  isArrayField,
  arrayFieldName,
  arrayFieldIndex,
  defaultValue,
  onChange,
  onBlur,
  removeFieldOnUnmount,
  ...rest
}: ListboxFieldProps) => {
  useRemoveFieldOnUnmount(form, name, removeFieldOnUnmount)

  return (
    <FieldSet
      name={name}
      form={form}
      wrapperClassName={wrapperClassName}
      arrayFieldIndex={arrayFieldIndex}
      arrayFieldName={arrayFieldName}
      isArrayField={isArrayField}
      {...rest}
    >
      <form.Field name={name} validators={validators} defaultValue={defaultValue}>
        {(field) => (
          <ListboxInput
            {...rest}
            name={name}
            value={field.state.value}
            hasError={field.state.meta.errors?.length > 0}
            onBlur={onBlurHandler(field, onBlur)}
            onChange={({ value }) => {
              if (onChange) onChange(value as any)
              field.handleChange(value as string)
            }}
          />
        )}
      </form.Field>
    </FieldSet>
  )
}

export default ListboxField
