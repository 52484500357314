import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react'
import clsx from 'clsx'
import debug from 'debug'
import { Fragment, useRef } from 'react'
import Icon from 'src/components/icons/icon'

import { FormInputProps } from '../fields/types'

const logger = debug('app:listbox-input')

// UPDATE NOTE: most of our older usages store the "value" in react-hook-form as an array with a single ListOption object
//  - This has led to a lot of verbose code to wrap/unwrap values to and from ListOption objects.
//  - Values can now be tracked as JUST the string/null value, and the component will handle wrapping/unwrapping internally as needed.
//  - If passing in value/onChangeHandler and using without react-hook-form, you'll continue to recieve the full ListOption object and need
//    to do _unwrapping_ yourself, but pretty simple in that case.

// Generic so you can type the value if it's something other than a string
export interface ListOption<ValueType = string | null> {
  value: ValueType
  label: string | JSX.Element
  description?: string
  disabled?: boolean
  divider?: boolean
}

export type ListboxInputProps = FormInputProps & {
  options: ListOption[]
  portal?: boolean
  compact?: boolean
  optionsClassName?: string
  onChange?: (value: ListOption) => void
  hasError?: boolean
  value?: string
}

const ListboxInput = ({
  name,
  label,
  useNestedLabel,
  placeholder,
  options,
  disabled,
  className,
  optionsClassName,
  style,
  onChange,
  value,
  hasError,
  compact = false,
  portal = true,
}: ListboxInputProps) => {
  if (useNestedLabel && !label) {
    throw new Error('useNestedLabel requires a label')
  }
  // Track the width of the button to set the width of the options list (when using portal)
  const buttonRef = useRef<HTMLDivElement>(null)
  const matchingItem = options.find((o) => o.value === value) as ListOption

  return (
    <Listbox value={matchingItem} by='value' onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <div className={clsx('relative', compact && 'mt-1', className)} style={style} ref={buttonRef}>
          <ListboxButton
            id={name}
            aria-describedby={`${name}-description`}
            className={clsx(
              'relative w-full cursor-default rounded-md border bg-white pl-3 pr-10 text-left shadow-sm focus:outline-none focus:ring-1 sm:text-sm',
              hasError && 'border-error-8 focus:border-error-5 focus:ring-error-5',
              disabled && 'cursor-not-allowed border-gray-400 text-gray-400',
              !hasError && !disabled && 'border-black focus:border-gray-500 focus:ring-gray-500',
              !compact && 'max-h-[44px] min-h-[44px]',
              compact && 'h-[34px] max-h-[34px]',
            )}
          >
            <div className='truncated flex flex-wrap gap-1 overflow-hidden'>
              {!matchingItem && (placeholder || useNestedLabel) && (
                <span className='text-gray-500'>{useNestedLabel ? label : placeholder}</span>
              )}
              {useNestedLabel && matchingItem && (
                <span className={'absolute top-0 bg-transparent text-xs text-gray-500'}>{label}</span>
              )}
              <span className={clsx('block truncate', useNestedLabel && 'mt-2 font-semibold text-gray-700')}>
                {matchingItem?.label}
              </span>
            </div>
            <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
              <Icon name='chevron-down' size={18} style={{ color: disabled ? 'rgb(156, 163, 175)' : '#000' }} />
            </span>
          </ListboxButton>
          <Transition
            show={open}
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <ListboxOptions
              anchor='bottom start'
              className={clsx(
                'border-neutral-4 absolute z-10 max-h-60 overflow-auto rounded-md border bg-white text-base shadow-2xl focus:outline-none sm:text-sm',
                optionsClassName,
              )}
              style={{ width: buttonRef.current?.offsetWidth }}
              portal={portal}
            >
              {options.map((option, i) => {
                if (option.divider) {
                  return <hr className='my-2 w-full' key={i} />
                }
                return (
                  <ListboxOption
                    key={option.value}
                    className={({ focus }) =>
                      clsx(
                        'relative w-full cursor-default select-none py-2 pl-2 pr-9',
                        focus && 'bg-information-0',
                        option.disabled ? 'text-gray-400' : 'text-gray-900',
                      )
                    }
                    value={option}
                    disabled={option.disabled}
                  >
                    <>
                      <div className='flex flex-col gap-1'>
                        <span className='block truncate text-sm'>{option.label}</span>
                        <span className='text-neutral-8 block text-xs'>{option.description}</span>
                      </div>
                      {matchingItem?.value === option.value ? (
                        <span className='text-information-9 absolute inset-y-0 right-0 flex items-center pr-4'>
                          <Icon name='success' size={18} aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  </ListboxOption>
                )
              })}
            </ListboxOptions>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}

export default ListboxInput
