import clsx from 'clsx'
import { FocusEvent, ForwardedRef, forwardRef, ReactElement, RefObject, useState } from 'react'

import { FormInputProps } from '../fields/types'
import { useFocusOnError } from './use-focus-on-error'

export type TextInputProps = FormInputProps & {
  leftOfInput?: ReactElement
  rightOfInput?: ReactElement
  onChange?: (v: string) => void
  hasError?: boolean
  value?: string
}

const TextInput = (
  {
    name,
    className,
    disabled,
    label,
    leftOfInput,
    rightOfInput,
    placeholder,
    useNestedLabel,
    value = '',
    hasError,
    onChange,
    onBlur,
    onFocus,
    focusOnError = true,
    ...rest
  }: TextInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const [isFocused, setIsFocused] = useState(false)
  useFocusOnError(ref as RefObject<HTMLInputElement>, hasError, focusOnError)

  const inputClasses = clsx(
    className,
    'flex h-[44px] max-h-[44px] w-full flex-1 flex-col rounded-md border border-solid border-black p-3',
    hasError && 'border-2 border-red-500',
    useNestedLabel && !value && 'placeholder:text-gray-500',
    useNestedLabel && value && '!pb-2 !pt-4 font-semibold !text-gray-700',
    isFocused && !hasError && !disabled && '!border-gray-500 !ring-1 !ring-gray-500',
  )

  return (
    <div className='flex w-full'>
      {leftOfInput}
      <input
        {...rest}
        id={name}
        name={name}
        aria-describedby={`${name}-description`}
        className={inputClasses}
        disabled={disabled}
        placeholder={useNestedLabel ? (label as string) : placeholder}
        onFocus={(e: FocusEvent<HTMLInputElement, Element>) => {
          setIsFocused(true)
          if (onFocus) onFocus(e)
        }}
        onBlur={(e: FocusEvent<HTMLInputElement, Element>) => {
          setIsFocused(false)
          if (onBlur) onBlur(e)
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (onChange) {
            onChange(e.target.value)
          }
        }}
        value={value}
        ref={ref}
      />
      {rightOfInput}
    </div>
  )
}

export default forwardRef<HTMLInputElement, TextInputProps>(TextInput)
