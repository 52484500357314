export const UUID_V4_REGEX = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

export const VALID_URL_REGEX = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/

export const AWS_ACCOUNT_REGEX = /\d{12}/

export function awsService(serviceSlug: string) {
  return new RegExp(
    `^(arn:(?:aws|aws-cn|aws-us-gov):${serviceSlug}:[\\w\\d-*]*:\\d{0,12}:[\\w\\d-*]*\\/?[\\w\\d-*]*)(\\/.*)?.*$`,
  )
}

export type ValidationConfig = { pattern: RegExp; message: string }

// Create a validator function that can be used in the tanstack form input
export const createValidator = (cause: string, config?: ValidationConfig | ValidationConfig[], required = false) => ({
  [cause]: ({ value }: { value: string }) => {
    if (!value || value === '') {
      return required ? 'This field is required' : undefined
    }

    if (!config) return undefined

    if (Array.isArray(config)) {
      return config.find(({ pattern, message }) => (!pattern.test(value) ? message : undefined))
    }
    return !config.pattern.test(value) ? config.message : undefined
  },
})

export const createOnBlurValidator = (config?: ValidationConfig | ValidationConfig[], required = false) =>
  createValidator('onBlur', config, required)

export const isRequiredOnBlur = createOnBlurValidator(undefined, true)

export const createOnChangeValidator = (config?: ValidationConfig | ValidationConfig[], required = false) =>
  createValidator('onChange', config, required)

export const isRequiredOnChange = createOnChangeValidator(undefined, true)
