import { FormApi } from '@tanstack/react-form'
import { FormHTMLAttributes, ForwardedRef, PropsWithChildren, useEffect, useState } from 'react'

import { submitHandler } from './utils'

export type FormProps = PropsWithChildren &
  FormHTMLAttributes<HTMLFormElement> & {
    form?: FormApi<any>
  }

const Form = ({ form, children, onSubmit, ...rest }: FormProps) => {
  if (!form && !onSubmit) {
    throw new Error('Form component must have a form prop or onSubmit prop provided')
  }

  // if onSubmit is provided, use it, otherwise use the submitHandler
  const handleSubmit = onSubmit ?? submitHandler(form!)

  return (
    <form onSubmit={handleSubmit} {...rest}>
      {children}
    </form>
  )
}

export default Form
