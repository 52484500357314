import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import useApi from 'src/utils/hooks/use-api'
import { useGetOrg } from 'src/utils/hooks/use-get-org'
import { useIsEnterpriseOrgMember } from 'src/utils/hooks/use-is-org-member'
import { useTrackLastVisited } from 'src/utils/hooks/use-track-last-visited'
import { userState } from 'src/utils/state'

import { UniDropdown } from './uni-dropdown'
import { UserDropdown } from './user-dropdown'

export function Logo({ light = false }) {
  return <img className='w-32' src={light ? '/logo-light.svg' : '/logo.svg'} alt='Vendia' />
}

function NavBar() {
  // Keep track of last visited node and uni to redirect user there on login
  // Need to stick this into a component that's alawys rendered in the app to pick
  // up the uni/node params from the URL when available
  useTrackLastVisited()

  const [user] = useRecoilState<any>(userState)
  const getOrg = useGetOrg()
  const org = getOrg?.data?.getOrganization
  const { data: isOrgMember } = useIsEnterpriseOrgMember()

  // const theme = useVendiaTheme()
  const theme = { name: 'light' } // in case we start using the themes again one day
  const isDarkTheme = theme.name === 'dark'

  let notice
  if (user && user.email_verified === false && !window.location.pathname.match(/\/settings\/email/)) {
    notice = (
      <div style={{ marginLeft: 20 }}>
        <Link to='/settings/email'>
          Notice: Please verify your email &nbsp;
          <strong>{user.email}</strong>
        </Link>
      </div>
    )
  }

  const navClasses = clsx(
    'relative z-30 flex items-center justify-between px-6 py-4',
    !isDarkTheme && 'bg-white',
    isDarkTheme && 'from-primaryCore to-purple-1300 bg-gradient-to-r',
  )

  return (
    <nav className={navClasses}>
      <div>
        <span>
          <Link to='/' data-testid='navbar-home-link'>
            <Logo light={isDarkTheme} />
          </Link>
        </span>
        {notice}
      </div>
      <div className='flex items-center gap-4'>
        <UniDropdown />
        <UserDropdown />
      </div>
    </nav>
  )
}

export default NavBar
