import { useQuery } from '@tanstack/react-query'
import { Uni } from '@vendia/management-api-types'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import useApi from 'src/utils/hooks/use-api'
import notify from 'src/utils/notify'

import { selectedRoleState } from '../state'

export const useGetUni = () => {
  const api = useApi()
  const navigate = useNavigate()
  const { id: uniName } = useParams()
  const [uniStatus, setUniStatus] = useState<string>()
  const selectedRoleName = useRecoilValue(selectedRoleState).name

  const response = useQuery({
    queryKey:
      // Adding selected role to query key forces the UI to refresh on role switch and kicks users back to dashboard if they're
      // on any page nested within the /uni/my-uni-name path (e.g. /uni/my-uni-name, /uni/my-uni-name/nodeone/details, etc.)
      ['getUni', selectedRoleName, uniName],
    queryFn: async () => {
      const data = await api.getUni<{ getUni?: Uni }>({ uniId: uniName })
      if (data.errors) {
        // Users typically hit a uni "not found" error if they're in a stale tab (logged into another account since) or "unathorized to retrieve" if the Uni exists
        // but they aren't allowed to view it. In any case, bounce them back to /unis and show an error message.
        navigate('/uni')
        notify.error(`Could not load Uni: ${uniName}. Encountered the following error: ${data.errors?.[0]?.message}`)
        return
      }

      setUniStatus(data.getUni?.status)

      const getUni = {
        ...data.getUni,
        schemaNamespace: 'Self',
      }

      // Fix for dynamodb sentinel value (replaces empty string in db) leaking to frontend
      if (getUni.alias === '\u0000' || getUni.alias === '\x00') {
        getUni.alias = null
      }

      return {
        ...data,
        getUni,
      }
    }, // Similar to useListUnis, make sure we've finished initializing selected role before firing request.
    enabled: Boolean(api) && Boolean(selectedRoleName) && Boolean(uniName),
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: uniStatus && uniStatus !== 'RUNNING' ? 30 * 1000 : false,
    refetchIntervalInBackground: true,
    retry: 2,
  })

  // if (response.isSuccess) {
  //   setUniStatus(response.data?.getUni?.status)
  // }

  return response
}
