import { FieldApi, FormApi } from '@tanstack/react-form'
import { FocusEvent, FocusEventHandler, FormEvent, useEffect } from 'react'

export const onBlurHandler = (field: FieldApi<any, any>, onBlur?: FocusEventHandler) => (e: FocusEvent) => {
  if (onBlur) {
    onBlur(e)
  }
  field.handleBlur()
}

export const onChangeHandler =
  <T = string | number>(field: FieldApi<any, any>, onChange?: (value: T) => void) =>
  (value: T) => {
    if (onChange) {
      onChange(value)
    }
    field.handleChange(value)
  }

export const submitHandler =
  <TFormData = any>(form: FormApi<TFormData>) =>
  (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    form.handleSubmit()
  }

export const useRemoveFieldOnUnmount = (form: FormApi<any>, name: string, removeFieldOnUnmount = false) => {
  useEffect(() => {
    return () => {
      if (removeFieldOnUnmount) {
        // Remove from form when unmounted
        form.deleteField(name)
      }
    }
  }, [])
}
