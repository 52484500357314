import clsx from 'clsx'
import React, { ChangeEvent, ForwardedRef, forwardRef } from 'react'

import { FormInputProps } from '../fields/types'

export type CheckboxProps = FormInputProps & {
  kind?: string
  className?: string
  wrapperClassName?: string
  children?: React.ReactNode
  disabled?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>, value?: string) => void
  defaultChecked?: boolean
  isPartiallyChecked?: boolean
  checked?: boolean
  value?: string
  hasError?: boolean
}

const Checkbox = (
  {
    name,
    label,
    kind,
    className,
    wrapperClassName,
    checked,
    isPartiallyChecked = false,
    children,
    value,
    hasError,
    onChange,
    ...rest
  }: CheckboxProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const inputClasses = clsx(
    className,
    'color-white mr-2 h-4 w-4 shrink-0 cursor-pointer appearance-none rounded-md border-2 border-[#26045D] bg-white p-0',
    `checked:border-transparent checked:bg-[#26045D] checked:bg-[url('/images/checkbox-check.svg')] checked:bg-[length:90%_90%] checked:bg-[1px_-1px] checked:bg-no-repeat`,
    isPartiallyChecked && '-checked:absolute -checked:left-[4px] -checked:top-[-1px] -checked:content-[2014]',
    rest.disabled && 'cursor-not-allowewd !border-[#ccc] !bg-[#ccc]',
    hasError && 'border-red',
  )

  return (
    <div className={clsx('py-1', wrapperClassName)}>
      <label
        className={clsx(
          'relative flex cursor-pointer items-center text-sm',
          rest.disabled && 'cursor-not-allowed',
          hasError && 'text-red',
        )}
      >
        <input
          {...rest}
          ref={ref}
          type='checkbox'
          className={inputClasses}
          checked={checked}
          value={value}
          onChange={(e) => {
            if (onChange) {
              onChange(e, value)
            }
          }}
        />
        <div className='text-sm'>{label}</div>
      </label>
    </div>
  )
}

export default forwardRef<HTMLInputElement, CheckboxProps>(Checkbox)
