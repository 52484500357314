import { ReactNode } from 'react'

interface PageHeaderProps {
  title?: string
  description?: string | ReactNode
  testid?: string
  image?: ReactNode
  imageSource?: string
  imageAlt?: string
  imageClassName?: string
  actions?: ReactNode
  titleBefore?: ReactNode
  titleAfter?: ReactNode
}

export default function PageHeader({
  title = '',
  description = '',
  testid = '',
  image = null,
  imageSource = '',
  imageAlt = '',
  imageClassName = '',
  actions,
  titleBefore,
  titleAfter,
}: PageHeaderProps) {
  return (
    <div className='relative flex justify-between pl-7 pr-6 pt-6'>
      <div className='flex'>
        {image && <div className='mr-2'>{image}</div>}
        {imageSource && <img className={imageClassName} src={imageSource} alt={imageAlt} width='56' height='56' />}
        <div className='flex flex-col justify-center'>
          <h2 className='m-0 text-2xl font-semibold' data-testid={testid}>
            <span className='flex items-center space-x-2'>
              {titleBefore}
              <span className=''>{title}</span>
              {titleAfter}
            </span>
          </h2>
          {description && <div className='text-neutral-9 mt-2 text-sm'>{description}</div>}
        </div>
      </div>
      <div className='flex items-center'>{actions}</div>
    </div>
  )
}
