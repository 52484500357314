import { RefObject, useEffect } from 'react'

// Hook to focus on an element when it has an error
export const useFocusOnError = (ref: RefObject<HTMLOrSVGElement>, hasError?: boolean, focusOnError?: boolean) => {
  useEffect(() => {
    if (focusOnError && hasError) {
      ref?.current?.focus()
    }
  }, [focusOnError, hasError, ref?.current])
}
